<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.shopExpenses') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-6 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <Fieldset :legend="$t('reports.addNewShopExpense')" :toggleable="true" :collapsed="true" v-if="hasAccess(availableMenus, editPage)">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <Calendar ref="expenseDate" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="expenseDate"></Calendar>
                                <label>{{ $t('sportsbets.date') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <Dropdown style="width: 100%" v-model="addShopsModel" :options="addShops" optionLabel="name" :filter="!this.isMobile()" />
                                <label>{{ $t('dropdown.placeholder.shop') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText type="number" step="0.01" style="width: 100%" v-model="amountModel" />
                                <label>{{ $t('bets.amount') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText style="width: 100%" type="text" v-model="detailsModel" />
                                <label>{{ $t('transactions.details') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-right">
                            <Button @click="addShopCD()" :disabled="disableSubmit" :label="$t('buttons.add')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                        </div>
                    </div>
                </Fieldset>
                <div class="card" v-if="showReportData">
                    <DataTable :value="reportData" ref="datatable" responsiveLayout="scroll">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column :header="$t('system.code')" field="shopCode" :sortable="true"> </Column>
                        <Column :header="$t('system.shop')" field="shopId" :sortable="true">
                            <template #body="slotProps"> {{ shopsDictionary[slotProps.data.shopId] }}</template></Column
                        >
                        <Column field="date" :header="$t('sportsbets.date')" :sortable="true">
                            <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.date) }} </template>
                        </Column>
                        <Column field="amount" :header="$t('bets.amount')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.amount) }} </template>
                        </Column>
                        <Column :header="$t('transactions.details')" field="details"> </Column>
                        <Column :header="$t('transactions.addedBy')" field="addedBy" :sortable="true"> </Column>
                        <Column :header="$t('transactions.addDate')" field="dateAdd" :sortable="true">
                            <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.dateAdd) }} </template></Column
                        >
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoApi } from '../../../service/BoApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            startDateModel: null,
            endDateModel: null,
            expenseDate: null,
            errorKeyCount: 0,
            showError: false,
            shops: [],
            shopsModel: { name: 'All', _id: 'all' },
            addShops: [],
            addShopsModel: { name: 'All', _id: 'all' },
            tranTypes: [
                { name: 'Shop Credit', id: 'SHOP_CREDIT' },
                { name: 'Shop Debit', id: 'SHOP_DEBIT' },
            ],
            tranTypesModel: '',
            amountModel: '',
            detailsModel: '',
            disableSubmit: false,
            crtRoute: 'shopExpenses',
            shopsDictionary: [],
        };
    },
    mounted() {
        this.getShops();
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.showReport();
    },
    computed: {
        editPage() {
            return this.crtRoute + '/add';
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    watch: {
        shopsModel() {
            if (!this.notNullOrEmptyObject(this.shopsModel)) {
                this.shopsModel = { name: 'All', _id: 'all' };
            }
            this.showReport();
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            code: shopsResponse.data[i].code,
                            _id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.addShops = shopsDdlValues;
                    this.createShopsDictionary();
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        showReport() {
            this.showReportData = false;
            this.isReportLoading = true;
            this.BoApi.getShopExpenses(this.shopsModel._id, this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel))
                .then((reportResponse) => {
                    if (reportResponse.data != null) {
                        this.reportData = reportResponse.data;
                        this.tableKey++;
                    }
                    this.isReportLoading = false;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportLoading = false;
                });
        },
        /*  saveXls() {
            this.isReportDownloading = true;
            this.BoSaveReportsApi.saveShopCD(this.shopsModel._id, this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel))
                .then((response) => {
                    this.isReportDownloading = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'ShopsCreditDebit_' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportDownloading = false;
                });
        },*/
        addShopCD() {
            this.disableSubmit = true;
            if (this.notNullOrEmptyObject(this.addShopsModel) && this.notNullOrEmptyField(this.amountModel) && this.notNullOrEmptyField(this.expenseDate) && this.notNullOrEmptyField(this.detailsModel)) {
                if (this.addShopsModel._id != 'all') {
                    this.BoApi.addShopExpense(this.addShopsModel._id, this.addShopsModel.code, this.amountModel, this.formatEnDateAndTime(this.expenseDate), this.detailsModel)
                        .then(() => {
                            this.showToast('success', this.$t('alerts.okAction'));
                            this.showReport();
                            this.disableSubmit = false;
                            this.hideAddGrid();
                        })
                        .catch((error) => {
                            this.disableSubmit = false;
                            this.checkForUnauthorizedError(error);
                            this.showToast('error', this.$t('alerts.generalError'));
                        });
                } else {
                    this.showToast('error', this.$t('alerts.selectOneShop'));
                    this.disableSubmit = false;
                }
            } else {
                this.showAllFieldsError = true;
                this.disableSubmit = false;
                this.showToast('error', this.$t('alerts.mandatoryFieldsAll'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        hideAddGrid() {
            this.expenseDate = null;
            this.amountModel = '';
            this.detailsModel = '';
            this.addShopsModel = { name: 'All', _id: 'all' };
        },
        createShopsDictionary() {
            for (let i = 0; i < this.shops.length; i++) {
                this.shopsDictionary[this.shops[i]._id] = this.shops[i].code + ' - ' + this.shops[i].name;
            }
        },
    },
};
</script>